<template>
<div div class="mobile-page-layout">
    <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
   
                <router-link :to="{ name: 'Dashboards'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>

                <div class="mobile-page-header-icon">
                    <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">viewBox="0 0 512 512">
                        <path class="fa-primary" d="M408 360H472C485.3 360 496 370.7 496 384C496 397.3 485.3 408 472 408H408V472C408 485.3 397.3 496 384 496C370.7 496 360 485.3 360 472V408H296C282.7 408 272 397.3 272 384C272 370.7 282.7 360 296 360H360V296C360 282.7 370.7 272 384 272C397.3 272 408 282.7 408 296V360z" />
                        <path class="fa-secondary" d="M32 80C32 53.49 53.49 32 80 32H176C202.5 32 224 53.49 224 80V176C224 202.5 202.5 224 176 224H80C53.49 224 32 202.5 32 176V80zM32 336C32 309.5 53.49 288 80 288H176C202.5 288 224 309.5 224 336V432C224 458.5 202.5 480 176 480H80C53.49 480 32 458.5 32 432V336zM432 32C458.5 32 480 53.49 480 80V176C480 202.5 458.5 224 432 224H336C309.5 224 288 202.5 288 176V80C288 53.49 309.5 32 336 32H432z" />
                    </svg>
                </div>
                <div class="mobile-page-header-text">{{ dashboard.title }}</div>  
                
                <div class="mobile-page-header-icon pointer mini-icon" v-if="editable" @click="open_dashboard_edit_modal()">
                    <i class="header-uil-icon uil uil-edit-alt"></i>
                </div>
            </div>
            <div class="mobile-page-header-actions">
      
                  <div class="mobile-page-header-icon pointer" @click.prevent="open_widgets_modal()">
                    <i class="header-uil-icon uil uil-plus-circle"></i>
                </div>

                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in menus" :key="index"  @click.prevent="get_modal(item.modal)">
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div>



    <slot v-if="loading">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>

    <slot v-else>
        <template v-if="widgets.length">
            <!-- باکس ویجت ها -->
            <draggable class="row tgju-widgets-row dashboard-widget-list widget-border-fix" :class="{ 'dashboard-page-loader': loading }" v-model="widgets" group="widgets" @start="drag=true" @end="saveWidgetsSort()" ghost-class="drag-ghost" handle=".drag-move" tag="ul">
                <template v-for="item in widgets">
                    <li class="tgju-widgets-block col-12 mb-m-2 draggable-element" :class="item.sizeClass" :key="item.widget.id" :data-widget-type="item.widget.type" :data-widget-id="item.widget.id" :data-widget-size="item.widget.size">
                        <div class="tgju-widget light">
                            <div class="list-widget  list-row bold list-row-title">
                                <div class="list-col l-item pointer hover head-color" v-tooltip="'جابجایی'" v-if="editable">
                                    <i class="uil uil-draggabledots drag-move"></i>
                                </div>
                                <div class="list-col l-title head-color" :class="{ 'dot': !editable }">
                                    <span>{{ item.widget.title }}</span>
                                </div>
                                <div class="list-col l-item pointer hover head-color" v-tooltip="'تنظیمات'" @click="configClicked(item.widget)" v-if="editable">
                                    <i class="uil uil-setting"></i>
                                </div>
                                <div class="list-col l-item pointer hover head-color resize-icon" v-if="editable" @click="openWidgetResizer(item.widget.id)">
                                    <i class="uil uil-arrows-h-alt" v-tooltip="'تغییر‌اندازه'" :class="{ 'widget-resizer-show': item.resizerShow }">
                                        <div class="widget-resizer">
                                            <span class="widget-resizer-sm" @click="changeWidgetSize(item.widget.id, 'small')">کوچک</span>
                                            <span class="widget-resizer-md" @click="changeWidgetSize(item.widget.id, 'medium')">متوسط</span>
                                            <span class="widget-resizer-lg" @click="changeWidgetSize(item.widget.id, 'large')">بزرگ</span>
                                            <span class="widget-resizer-xl" @click="changeWidgetSize(item.widget.id, 'full')">کامل</span>
                                            <div></div>
                                        </div>
                                    </i>
                                </div>
                                <div class="list-col l-item pointer hover head-color" v-tooltip="'حذف'" v-if="editable" @click="removeWidget(item.widget.id)"><i class="uil uil-trash-alt remove-widget"></i></div>
                            </div>
                            <div class="tgju-widget-content full-padding dashboard-edit-item">
                                <div class="tables-default normal overflow-unset dashboard-widget-min">
                                    <component v-bind:is="item.component" :full="true" :widget="item.widget" :data="item.data" :editable="editable" :ref="`widget_${item.widget.id}`" wrapper="dashboard"></component>
                                </div>
                            </div>
                        </div>
                    </li>
                </template>
            </draggable>
        </template>

        <template v-else>
            <!-- در صورتی که ویجتی اد نشده بود این بخش نمایش باید داده شود -->
            <div class="row tgju-widgets-row widget-padding noWidget-block">
                <div class="col-12">
                    <div class="noWidget noContent">
                        <div>
                            <i class="uil uil-layer-group-slash noContent-icon" aria-hidden="true"></i>
                            <p class="noContent-title">در حال حاضر ویجتی برای نمایش وجود ندارد!</p>
                            <a href="" class="tgju-btn  m-top tgju-btn-primary" @click.prevent="open_widgets_modal()" v-tooltip="'افزودن ویجت'"><i class="uil uil-plus-circle" style="display: inline-block;"></i> افزودن ویجت</a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </slot>
</div>
</template>

<script>
// این ویو برای ویرایش داشبورد استفاده میشود
import draggable from 'vuedraggable'

export default {
    name: 'DashboardEdit',
    components: {
        draggable
    },
    data: function () {
        return {
            dashboards: [],
            dashboard: {
                id: null,
                user_id: null,
                title: '',
                type: null,
                default: false,
                created_at: null,
            },
            widgets: [],
            loading: true,
            editable: false,
            widgetLoad: true,
            menus: [
                { title: 'راهنما', modal: 'help' }
            ],
        }
    },
    mounted() {
        if (typeof this.$router.currentRoute.params.id != 'undefined') {
            this.dashboard.id = this.$router.currentRoute.params.id;
        }

        this.editable = this.$router.currentRoute.meta.editable;
        this.loadDashboards();

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        loadDashboards() {
            let dashboards = this.$store.state.dashboards;
            if (!dashboards.length) {
                this.loading = false;
                return;
            }

            dashboards.forEach(dashboard => {
                if (this.dashboard.id && dashboard.id == this.dashboard.id) {
                    this.dashboard = dashboard;
                } else if (!this.dashboard.id && dashboard.default) {
                    this.dashboard = dashboard;
                }
            });

            this.dashboards = dashboards;

            if (!this.dashboard.id && dashboards) {
                this.dashboard = dashboards[0];
            }

            if (this.dashboard.id) {
                this.loadWidets();
            }
        },
        // این متد برای بارگذاری ویجت ها استفاده میشود
        loadWidets() {
            this.$helpers.getWidgets({ dashboard_id: this.dashboard.id }).then((widgets) => {
                widgets.forEach((item, index) => {
                    if (!item.component) {
                        widgets.splice(index, 1);
                        return;
                    }

                    Vue.component(
                        item.component,
                        () => import(`@/components/${item.component}`)
                    );
                });

                this.widgets = widgets;
                this.loading = false;
            });
        },
        // این متد برای هندل کردن سورت و ترتیب قرار گیری ویجت ها استفاده میشود
        saveWidgetsSort() {
            let sort = [];
            this.widgets.forEach(item => {
                sort.push(item.widget.id);
            });

            let api_data = {
                dashboard_id: this.dashboard.id,
                sort: JSON.stringify(sort),
            };

            this.$helpers.makeRequest('POST', '/dashboard/widget-sort', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    //
                }
            });
        },
        // از این متد جهت هندل کردن تغییر سایز ابعاد لی اوت ویجت استفاده میشود
        openWidgetResizer(id) {
            let index = this.getIndex(id);
            this.widgets[index]['resizerShow'] = !this.widgets[index]['resizerShow'];
        },
        // این متد جهت اعمال تغییرات سایز ویجت استفاده میشود
        changeWidgetSize(id, size) {
            let index = this.getIndex(id);
            let sizeClass = this.$helpers.widgetSize(size);
            this.widgets[index].widget.size = size;
            this.widgets[index].sizeClass = sizeClass;

            let widget = this.$helpers.unbindObject(this.widgets[index].widget);
            this.$helpers.saveWidget(widget);

            this.$root.$emit('widgetSizeChanged');
        },
        // از این متد جهت حذف کردن ویجت استفاده میشود 
        removeWidget(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این ویجت اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let index = this.getIndex(id);
                    this.widgets.splice(index, 1);
                    this.$helpers.makeRequest('DELETE', '/dashboard/widget-delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                title: "ویجت با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف ویجت رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // این متد جهت هندل کردن و باز کردن مودال افزودن ویجت جدید استفاده میشود
        open_widgets_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_addWidget_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'Widgets.vue',
                config: {
                    title: 'افزودن ویجت',
                    icon: 'uil uil-plus-circle with-icon',
                    smallModal: false
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                // TODO:موقت مشکل کلیک زیاد حل شده Issues #23
                this.loading = true;
                $("#global-dashboard-modal").hide();
                this.$helpers.getWidgets({ widget_type: response.data.type, store: true, dashboard_id: this.dashboard.id }).then((widgets) => {
                    widgets.forEach(item => {
                        Vue.component(
                            item.component,
                            () => import(`@/components/${item.component}`)
                        );
                        this.widgets.push(item);
                        this.loading = false;
                        this.$swal.fire({
                            icon: "success",
                            title: `ویجت ( ${item.widget.title} ) با موفقیت اضافه شد.`,
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    });
                });
            });
        },
        // از این متد جهت بازکردن مودال افزودن داشبورد جدید استفاده میشود
        open_dashboard_add_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_manageDashboards_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'DashboardAdd.vue',
                config: {
                    title: 'افزودن داشبورد',
                    icon: 'uil uil-plus-circle with-icon',
                    smallModal: true,
                    data: {
                        dashboards: this.dashboards
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'addDashboard') {
                    this.$store.commit('addDashboard', { dashboard: response.data.dashboard });
                }
            });
        },
        // از این متد جهت بازکردن مودال لیست داشبورد ها استفاده میشود 
        // وضعیت کنونی : غیرفعال و استفاده نمیشود
        open_dashboard_list_modal() {
            this.loadDashboards();

            let callbackEvent = this.$router.currentRoute.name + '_manageDashboard_list_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'DashboardList.vue',
                config: {
                    title: 'مدیریت داشبورد ها',
                    icon: 'uil uil-setting with-icon',
                    smallModal: true,
                    data: {
                        dashboards: this.dashboards
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'removeDashboard') {
                    this.$store.commit('removeDashboard', { id: response.data.id });
                }
            });
        },
        // از این متد جهت باز کردن مودال ویرایش عنوان داشبورد استفاده میشود
        open_dashboard_edit_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_dashboardEdit_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'DashboardEdit.vue',
                config: {
                    title: 'ویرایش داشبورد',
                    icon: 'uil uil-edit-alt with-icon',
                    smallModal: true,
                    data: {
                        dashboard: this.dashboard
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                this.$store.commit('editDashboard', { dashboard: response.data.dashboard });
            });
        },
        getIndex(id) {
            let index;

            this.widgets.forEach((item, _index) => {
                if (item.widget.id == id) {
                    index = _index;
                }
            });

            return index;
        },
        configClicked(widget) {
            this.$refs[`widget_${widget.id}`][0].open_settings_modal();
        }, // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Dashboards.vue',
                title: 'داشبورد‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
